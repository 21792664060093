import {APPOINTMENT_CONFIG, RecurringType} from "@constants";
import {usePrevious} from "@hooks";
import {RecurringScheduleItemConfig, ScheduleItemType} from "@store";
import {
  Box,
  DateTimeField,
  Heading,
  MultiselectField,
  NumberField,
  Text,
  TextField,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useEffect} from "react";

interface RecurringScheduleItemProps {
  recurringType: RecurringType;
  recurringScheduleItem: RecurringScheduleItemConfig;
  setRecurringScheduleItem: (r: RecurringScheduleItemConfig) => void;
  disabled: boolean;
  type: string;
}

export const RecurringScheduleItemManager = ({
  recurringType,
  recurringScheduleItem,
  setRecurringScheduleItem,
  disabled,
  type,
}: RecurringScheduleItemProps): ReactElement => {
  const {startDatetime, endDatetime, durationMinutes, daysOfWeek} = recurringScheduleItem;
  const prevRecurringScheduleItem = usePrevious(recurringScheduleItem);

  // this is to check the previously selected start time against the currently selected start time
  // if they are not the same, update the other values to defaults based on the new start time
  useEffect(() => {
    const start = DateTime.fromISO(recurringScheduleItem.startDatetime);
    const prevStart =
      prevRecurringScheduleItem?.startDatetime &&
      DateTime.fromISO(prevRecurringScheduleItem?.startDatetime);

    if (!start.isValid || !prevStart) {
      console.warn("Invalid start or end", start, prevStart);
      return;
    }

    if (+prevStart === +start) {
      return;
    }
    const duration = APPOINTMENT_CONFIG[type as ScheduleItemType]?.duration ?? 30;

    setRecurringScheduleItem({
      ...recurringScheduleItem,
      daysOfWeek: start.toFormat("EEEE") ? [start.toFormat("EEEE")] : [],
      durationMinutes: duration,
      endDatetime: start.plus({years: 1}).toISO(),
    });
  }, [
    prevRecurringScheduleItem?.startDatetime,
    recurringScheduleItem,
    setRecurringScheduleItem,
    type,
  ]);

  return (
    <Box>
      <Box>
        <Heading size="sm">Recurring Schedule Item Configuration</Heading>
      </Box>
      <Box paddingY={2} zIndex="auto">
        <Box paddingY={2}>
          <Text>
            NOTE: Once a recurring series is created, you will be able to remove individual events
            from the series as well as end the series early and remove all future events, but you
            will not be able to edit any details. To edit details, please end the series and create
            a new one.
          </Text>
        </Box>
        <DateTimeField
          disabled={disabled}
          title="Start Date and Time"
          type="datetime"
          value={startDatetime}
          onChange={(r?: string): void => {
            if (!r) {
              return;
            }
            setRecurringScheduleItem({...recurringScheduleItem, startDatetime: r});
          }}
        />
      </Box>
      <Box paddingY={2} zIndex="auto">
        <NumberField
          disabled={disabled}
          title="Duration (minutes)"
          type="number"
          value={durationMinutes ? String(durationMinutes) : undefined}
          onChange={(r: string): void => {
            if (!r) {
              setRecurringScheduleItem({...recurringScheduleItem, durationMinutes: undefined});
              return;
            }
            setRecurringScheduleItem({...recurringScheduleItem, durationMinutes: parseInt(r)});
          }}
        />
      </Box>
      <Box paddingY={2} zIndex="auto">
        <DateTimeField
          disabled={disabled}
          title="End Date"
          type="date"
          value={endDatetime}
          onChange={(r?: string): void => {
            if (!r) {
              return;
            }
            setRecurringScheduleItem({...recurringScheduleItem, endDatetime: r});
          }}
        />
      </Box>
      {recurringType === RecurringType.Weekly && (
        <Box>
          <TextField
            disabled={disabled}
            title="Repeat Every x weeks"
            value={recurringScheduleItem.interval?.toString()}
            onChange={(r: string): void => {
              if (!r) {
                setRecurringScheduleItem({...recurringScheduleItem, interval: undefined});
                return;
              }
              setRecurringScheduleItem({...recurringScheduleItem, interval: parseInt(r)});
            }}
          />
          <Box maxWidth={200}>
            <MultiselectField
              options={[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((d) => ({label: d, value: d}))}
              title="Days of the Week"
              value={daysOfWeek ?? []}
              onChange={(val: string[]): void => {
                setRecurringScheduleItem({...recurringScheduleItem, daysOfWeek: val});
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

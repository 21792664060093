import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Form,
  FormInstance,
  isSuperUserOrSupervisor,
  useDeleteFormInstancesByIdMutation,
  useGetFormInstancesQuery,
  useGetFormsQuery,
  useGetUsersByIdQuery,
  usePostFormInstancesMutation,
} from "@store";
import {StaffStackParamList} from "@types";
import {
  BorderTheme,
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  printDate,
  SelectField,
  Text,
  Tooltip,
  useToast,
} from "ferns-ui";
import sortBy from "lodash/sortBy";
import React, {FC, ReactElement, useEffect, useState} from "react";

import {LinkButton} from "./LinkButton";

const WIDTHS = ["30%", "20%", "10%", "40%"];

const rowBorder = {borderBottom: "default" as keyof BorderTheme};

interface FormSelectListProps {
  forms: Form[];
  userId: string;
  type: "Note" | "Assessment";
}

const FormSelectList: FC<FormSelectListProps> = ({forms, type, userId}) => {
  const toast = useToast();
  const [createFormInstance, {isLoading: createLoading}] = usePostFormInstancesMutation();
  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const [selectedFormId, setSelectedFormId] = useState<string | undefined>(undefined);

  const form = forms.find((f) => selectedFormId && f._id === selectedFormId);

  const options = [...sortBy(forms, "name")].map((f) => ({
    label: f.name,
    value: f._id,
    _id: f._id,
  }));

  // Once we've loaded forms, initialize selectedFormId to the first form
  useEffect(() => {
    if ((forms.length ?? 0) > 0 && selectedFormId === null) {
      setSelectedFormId(options[0]?._id!);
    }
  }, [forms, options, selectedFormId, setSelectedFormId]);

  return (
    <Box paddingY={4}>
      <Box marginBottom={2}>
        <Text bold size="lg">
          {`Assign ${type} To ${user?.name ?? "User"}`}
        </Text>
      </Box>

      <Box direction="row" width="100%">
        <Box flex="grow" marginRight={2}>
          <SelectField
            options={options}
            requireValue={false}
            value={selectedFormId}
            onChange={setSelectedFormId}
          />
        </Box>
        <Box justifyContent="center" marginBottom={5}>
          <Button
            disabled={createLoading}
            loading={createLoading}
            text="Assign"
            variant="secondary"
            onClick={async (): Promise<void> => {
              await createFormInstance({
                userId: userId as any,
                formId: selectedFormId!,
                type: form?.type,
              })
                .unwrap()
                .catch((error: any) => {
                  toast.catch(error, error?.data?.title ?? "Error assigning form");
                });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface Props {
  userId: string;
}

export const FormsPane = ({userId}: Props): ReactElement => {
  const profile = useReadProfile();
  const toast = useToast();
  const isSupervisor = isSuperUserOrSupervisor(profile);

  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const {data: forms} = useGetFormsQuery({});
  const formsToAssign = Boolean(user?.testUser)
    ? forms?.data
    : (forms?.data?.filter((f) => !f.isDraft) ?? []);
  // TODO: Remove the extra query in favor of query filtering useGetFormInstancesQuery based on
  // internalKey
  const {data: openNotesFormData} = useGetFormsQuery({name: "Open Notes"});
  const openNotesForm = openNotesFormData?.data?.[0];
  const {data: formInstancesData} = useGetFormInstancesQuery(
    openNotesForm ? {userId, formId: {$ne: openNotesForm._id} as any} : skipToken
  );

  const [createFormInstance, {isLoading: createLoading}] = usePostFormInstancesMutation();
  const [removeFormInstance, {isLoading: removeLoading}] = useDeleteFormInstancesByIdMutation();

  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();

  const formInstances =
    formInstancesData?.data?.filter(
      (fi) => !(fi.type === "Note" && (fi.form as Form).name === "Open Notes")
    ) ?? [];

  const notes = formInstances.filter((fi) => fi.type === "Note");
  const assessments = formInstances.filter((fi) => fi.type === "Assessment");

  const renderFormInstanceRow = (f: FormInstance): ReactElement => {
    const isPostCompletionStatus = ["Completed", "Signed", "Requires Supervisor Action"].includes(
      f.status
    );
    const isFinalOrRestricted = Boolean(
      f.status === "Signed" || (f.status === "Requires Supervisor Action" && !isSupervisor)
    );
    const isFormDeleted = !forms?.data?.find((fo) => fo._id === (f.form as any)._id);

    const formVisibility =
      ["Completed", "Sent To User", "Signed", "Requires Supervisor Action"].includes(f.status) &&
      (f.form as Form).patientVisibility;
    return (
      <Box key={f._id} direction="row" width="100%" {...rowBorder} paddingY={2}>
        <Box alignItems="center" direction="row" gap={2} width={WIDTHS[0]}>
          <Icon iconName={formVisibility ? "eye" : "eye-slash"} size="md" />
          <Box flex="grow" wrap>
            <Text size="sm">{(f.form as Form).name} </Text>
          </Box>
        </Box>

        <Box justifyContent="center" width={WIDTHS[1]}>
          {f.status === "Requires Supervisor Action" ? (
            <Box marginLeft={4}>
              <Tooltip text="Supervisor Signature Needed">
                <Icon color="warning" iconName="circle-exclamation" size="lg" />
              </Tooltip>
            </Box>
          ) : (
            <Text size="sm">{f.serviceDate ? printDate(f.serviceDate) : ""}</Text>
          )}
        </Box>

        {Boolean(f.type === "Assessment") && (
          <Box justifyContent="center" width={WIDTHS[2]}>
            {Boolean(isPostCompletionStatus && f.totalScore !== undefined) && (
              <Text size="sm">
                {f.totalScore}
                {f.scoreText && f.scoreText !== String(f.totalScore) ? `, ${f.scoreText}` : ""}
              </Text>
            )}
          </Box>
        )}

        {Boolean(f.type === "Note") && (
          <Box justifyContent="center" width={WIDTHS[2]}>
            <Icon
              color={f.status === "Signed" ? "success" : "secondaryLight"}
              iconName={f.status === "Signed" ? "check" : "x"}
            />
          </Box>
        )}

        <Box alignItems="center" direction="row" gap={1} justifyContent="start" width={WIDTHS[3]}>
          <LinkButton
            text="View"
            onClick={(): void => {
              navigation.navigate("ManageFormScreen", {
                formId: f._id,
                readOnly: true,
              });
            }}
          />
          {!isFinalOrRestricted && (
            <IconButton
              accessibilityLabel="Edit form"
              iconName="pencil"
              variant="muted"
              onClick={(): void => {
                navigation.navigate("ManageFormScreen", {
                  formId: f._id,
                });
              }}
            />
          )}

          {Boolean(!createLoading && !isFormDeleted) && (
            <IconButton
              accessibilityLabel="duplicate"
              confirmationText="Are you sure you want to duplicate this form?"
              iconName="copy"
              tooltipText="Duplicates the form with copy forward."
              variant="muted"
              withConfirmation
              onClick={async (): Promise<void> => {
                try {
                  await createFormInstance({
                    duplicateFormInstanceId: f._id,
                  } as any).unwrap();
                } catch (error: any) {
                  toast.catch(error, error?.data?.title ?? "Error duplicating form");
                }
              }}
            />
          )}

          {isSupervisor && (
            <IconButton
              accessibilityLabel="delete"
              confirmationText="Are you sure you want to remove this form?"
              iconName="trash"
              loading={removeLoading}
              variant="destructive"
              withConfirmation
              onClick={async (): Promise<void> => {
                await removeFormInstance(f._id);
              }}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box scroll>
      <Box direction="row" width="100%">
        <Box width={WIDTHS[0]}>
          <Text bold size="sm">
            Name
          </Text>
        </Box>
        <Box width={WIDTHS[1]}>
          <Text bold size="sm">
            Date Of Service
          </Text>
        </Box>
        <Box width={WIDTHS[2]}>
          <Text bold size="sm">
            Signed
          </Text>
        </Box>
        <Box width={WIDTHS[3]}>
          <Text bold size="sm">
            Manage
          </Text>
        </Box>
      </Box>
      {notes.map(renderFormInstanceRow)}
      <FormSelectList
        forms={formsToAssign?.filter((f) => f.type === "Note" && f.name !== "Open Notes") ?? []}
        type="Note"
        userId={userId}
      />
      <Box paddingY={2}>
        <Heading size="sm">Assessments</Heading>
      </Box>
      <Box direction="row" marginTop={2} width="100%">
        <Box width={WIDTHS[0]}>
          <Text bold size="sm">
            Name
          </Text>
        </Box>
        <Box width={WIDTHS[1]}>
          <Text bold size="sm">
            Date Of Service
          </Text>
        </Box>
        <Box width={WIDTHS[2]}>
          <Text bold size="sm">
            Score
          </Text>
        </Box>
        <Box width={WIDTHS[3]}>
          <Text bold size="sm">
            Manage
          </Text>
        </Box>
      </Box>
      {assessments.map(renderFormInstanceRow)}
      <FormSelectList
        forms={formsToAssign?.filter((f) => f.type === "Assessment") ?? []}
        type="Assessment"
        userId={userId}
      />
    </Box>
  );
};

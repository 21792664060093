import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {
  AlertInstance,
  setWorkflowMappingId,
  setWorkflowStaffId,
  useAppDispatch,
  usePatchAlertInstancesByIdMutation,
  WorkflowMappings,
} from "@store";
import {StaffStackParamList} from "@types";
import {IsWeb} from "@utils";
import {Badge, Box, Button, Card, printDate, Text} from "ferns-ui";
import React from "react";

import {LinkButton} from "../LinkButton";

const AlertCardRow = ({
  label,
  value,
  onClick,
}: {
  label: string;
  value: string;
  onClick?: any;
}): React.ReactElement => {
  return (
    <Box direction="row" paddingY={2} width="100%">
      <Box minWidth={100}>
        <Text bold>{label}:</Text>
      </Box>
      <Box flex="grow" justifyContent="center" width="100%" wrap>
        {Boolean(onClick) ? <LinkButton text={value} onClick={onClick} /> : <Text>{value}</Text>}
      </Box>
    </Box>
  );
};

interface AlertUpdateCardParams {
  alert: AlertInstance;
  profileUserId: string;
  profileUsersWorkFlow: WorkflowMappings | undefined;
  setShowSideDrawer: (show: boolean) => void;
}

export const AlertCard = ({
  alert,
  profileUserId,
  profileUsersWorkFlow,
  setShowSideDrawer,
}: AlertUpdateCardParams): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const isArchived = alert.archivedByUsers.includes(profileUserId);
  const [updateAlert] = usePatchAlertInstancesByIdMutation();

  let matchedWorkflowId: string | undefined;
  if (profileUsersWorkFlow?.data) {
    const matchedWorkflows = profileUsersWorkFlow.data.filter(
      (workflow) => workflow.userId._id === alert.associatedUserId._id
    );
    if (matchedWorkflows.length === 1) {
      matchedWorkflowId = matchedWorkflows[0]._id;
    }
  }

  const updateAlertResolution = async (): Promise<void> => {
    await updateAlert({id: alert._id, body: {resolved: !alert.resolved}});
  };

  const unarchiveAlert = async (): Promise<void> => {
    const updatedAlert = alert.archivedByUsers.filter((user) => user !== profileUserId);
    await updateAlert({id: alert._id, body: {archivedByUsers: updatedAlert}});
  };

  const archiveAlert = async (): Promise<void> => {
    const updatedAlert = [...alert.archivedByUsers, profileUserId];
    await updateAlert({id: alert._id, body: {archivedByUsers: updatedAlert}});
  };

  const redirectToAssociatedUser = (): void => {
    if (!matchedWorkflowId || !IsWeb) {
      navigation.navigate("User", {userId: alert.associatedUserId._id});
    } else {
      dispatch(setWorkflowMappingId(matchedWorkflowId));
      dispatch(setWorkflowStaffId(profileUserId));
      navigation.navigate("Staff", {screen: "Workflows"});
    }
    setShowSideDrawer(false);
  };

  return (
    <Card margin={1} paddingX={3} paddingY={2}>
      <Box alignItems="center" direction="row" justifyContent="between" marginTop={1}>
        <Box marginBottom={2} marginRight={1}>
          {alert.resolved ? (
            <Badge status="success" value="Resolved" />
          ) : (
            <Badge status="warning" value="Unresolved" />
          )}
        </Box>
      </Box>
      <Box direction="column" justifyContent="between" paddingX={2}>
        <AlertCardRow
          label="Patient"
          value={
            !matchedWorkflowId ? `*${alert?.associatedUserId?.name}` : alert?.associatedUserId?.name
          }
          onClick={redirectToAssociatedUser}
        />
        {alert.alertId.title && <AlertCardRow label="Alert Type" value={alert.alertId.title} />}
        <AlertCardRow label="Created" value={printDate(alert.created)} />
        {alert.dueDateText && <AlertCardRow label="Due" value={alert.dueDateText} />}
        {alert?.alertText && <AlertCardRow label="Details" value={alert.alertText} />}
        {alert.resolvedBy?.name && alert.resolved && (
          <AlertCardRow label="Resolved By" value={alert.resolvedBy.name} />
        )}
      </Box>
      <Box alignItems="center" direction="row" justifyContent="end" marginTop={2}>
        {alert.resolved && (
          <Box marginRight={2}>
            <Button
              text={isArchived ? "Move to Inbox" : "Archive"}
              variant="muted"
              onClick={isArchived ? unarchiveAlert : archiveAlert}
            />
          </Box>
        )}
        {!isArchived && (
          <Button
            disabled={isArchived}
            iconName={alert.resolved ? undefined : "check"}
            text={alert.resolved ? "Unresolve" : "Resolve"}
            variant={alert.resolved ? "outline" : "primary"}
            onClick={updateAlertResolution}
          />
        )}
      </Box>
    </Card>
  );
};

import {Box, Icon, Tooltip} from "ferns-ui";
import React, {ReactElement} from "react";

export interface Notification {
  _id: string;
  status: "Pending" | "Completed" | "Failed" | "Do Not Disturb" | "Unknown" | "Canceled";
  deliveryErrors?: string[];
}

interface DeliveryIconsProps {
  pushStatuses?: Notification[];
  smsStatuses?: Notification[];
}

export const DeliveryIcons = ({
  pushStatuses = [],
  smsStatuses = [],
}: DeliveryIconsProps): ReactElement | null => {
  if (pushStatuses.length === 0 && smsStatuses.length === 0) {
    return null;
  }

  // we will consider a delivery to be a success as long as one of the push statuses is a success
  const pushSuccess = pushStatuses.some((s) => s.status === "Completed");
  // fail if all failed
  const pushFailed = pushStatuses.every((s) => s.status === "Failed");
  const pushIcon = pushSuccess || !pushFailed ? "bell" : "bell-slash";
  let pushColor: "secondaryLight" | "error" | "success" = "secondaryLight";
  if (pushSuccess) {
    pushColor = "success";
  } else if (pushFailed) {
    pushColor = "error";
  }
  const smsSuccess = smsStatuses.some((s) => s.status === "Completed");
  const smsFailed = smsStatuses.every((s) => s.status === "Failed");
  const smsIcon = smsSuccess || !smsFailed ? "phone" : "phone-slash";
  let smsColor: "secondaryLight" | "error" | "success" = "secondaryLight";
  if (smsSuccess) {
    smsColor = "success";
  } else if (smsFailed) {
    smsColor = "error";
  }

  const pushDetails: string = pushStatuses
    .map((s) => {
      return `Notification ID: ${s._id}\nStatus: ${s.status}${
        s.deliveryErrors && s.deliveryErrors.length > 0 ? `\nError: ${s.deliveryErrors}` : ""
      }\n`;
    })
    .join("\n");

  const smsDetails: string = smsStatuses
    .map((s) => {
      return `Notification ID: ${s._id}\nStatus: ${s.status}${
        s.deliveryErrors && s.deliveryErrors.length > 0 ? `\nError: ${s.deliveryErrors}` : ""
      }\n`;
    })
    .join("\n");

  return (
    <Box direction="row">
      {pushStatuses.length > 0 && (
        <Tooltip text={pushDetails}>
          <Box padding={1}>
            <Icon color={pushColor} iconName={pushIcon} size="sm" />
          </Box>
        </Tooltip>
      )}
      {smsStatuses.length > 0 && (
        <Tooltip text={smsDetails}>
          <Box padding={1}>
            <Icon color={smsColor} iconName={smsIcon} size="sm" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {usePatchUsersByIdMutation, User} from "@store";
import {StaffStackParamList} from "@types";
import {FeatureFlags} from "@utils";
import {Box, Table, TableHeader, TableHeaderCell, TableRow, Text, useToast} from "ferns-ui";
import startCase from "lodash/startCase";
import React, {useContext} from "react";

import {CheckboxCell} from "./CheckboxCell";
import {nameColumn} from "./ExplorerColumns";
import {UserExplorerColumn, UserExplorerContext} from "./UserExplorerContext";

// A cell that allows checking and unchecking feature flags on the user.
export const FeatureFlagCell = ({
  user,
  flag,
}: {
  user: User;
  flag: FeatureFlags;
}): React.ReactElement => {
  const [updateUser] = usePatchUsersByIdMutation();
  const toast = useToast();
  return (
    <CheckboxCell
      editing
      field={`featureFlags.${flag}.enabled`}
      user={user}
      onChange={async (checked): Promise<void> => {
        await updateUser({
          id: user._id,
          body: {
            [`featureFlags.${flag}.enabled`]: checked,
          } as any,
        })
          .unwrap()
          .catch(toast.catch);
      }}
    />
  );
};

const featureFlagColumn: (flag: FeatureFlags) => UserExplorerColumn = (flag) => ({
  title: startCase(flag as string),
  width: flag.length * 8,
  Component: (user: User): React.ReactElement => <FeatureFlagCell flag={flag} user={user} />,
});

const featureFlagColumns: UserExplorerColumn[] = [
  nameColumn,
  featureFlagColumn("enableWebPush"),
  featureFlagColumn("generateFeedbackSurveys"),
  featureFlagColumn("enableTwilioCalling"),
  featureFlagColumn("appointments"),
  featureFlagColumn("enableTherapyCadenceIndicator"),
  featureFlagColumn("enableClinicalTabAssessmentCharts"),
];

export const FeatureFlagTable = (): React.ReactElement => {
  const context = useContext(UserExplorerContext)!;
  const {setSort, users, page, setPage, more, total} = context;
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();

  const totalPages = Math.ceil((total ?? 0) / 50);

  return (
    <Table
      columns={featureFlagColumns.map((c) => c.width)}
      more={more}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
    >
      <TableHeader>
        {featureFlagColumns.map((column, index) => (
          <TableHeaderCell
            key={column.title}
            index={index}
            sortable={Boolean(column.sort)}
            onSortChange={(direction): void => {
              if (!column.sort) {
                setSort(undefined);
              } else {
                setSort(direction ? [column.sort, direction] : undefined);
              }
            }}
          >
            <Box flex="grow" justifyContent="center" width="100%" wrap>
              <Text bold size="sm">
                {column.title}
              </Text>
            </Box>
          </TableHeaderCell>
        ))}
      </TableHeader>
      {users.map((user) => (
        <TableRow key={user._id}>
          {featureFlagColumns.map((col, i) => (
            <React.Fragment key={`featureFlagColumn-${i}-${user._id}`}>
              {col.Component(user, navigation as any)}
            </React.Fragment>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

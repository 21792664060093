import {Box, IconButton, Text} from "ferns-ui";
import React from "react";

import {Day, DayDropdown} from "./DayDropdown";
import {TimeDropdown, TimeResolution} from "./TimeDropdown";

export type DayTimeRange = {
  day: Day;
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
};

interface DayTimeRangeProps {
  dayTimeRange: DayTimeRange;
  onChange: (value: DayTimeRange) => void;
  onDelete?: () => void;
  // Whether to show a day picker or just show the day as text.
  disablePickDay?: boolean;
  resolution?: TimeResolution;
  showDelete?: boolean;
}

export const DayTimeRange = ({
  dayTimeRange,
  onChange,
  disablePickDay = false,
  resolution = "30",
  showDelete = false,
  onDelete,
}: DayTimeRangeProps): React.ReactElement => {
  const renderDayPicker = (): React.ReactElement => {
    if (!disablePickDay) {
      return (
        <Box marginRight={4} width={120}>
          <DayDropdown
            day={dayTimeRange?.day}
            onChange={(d): void => onChange({...dayTimeRange, day: d})}
          />
        </Box>
      );
    } else {
      return (
        <Box height="100%" marginRight={2} marginTop={4} width={80}>
          <Text bold>{dayTimeRange?.day ?? "Unknown"}</Text>
        </Box>
      );
    }
  };

  return (
    <Box alignItems="center" direction="row" width="100%">
      {renderDayPicker()}
      <Box marginRight={2}>
        <TimeDropdown
          hour={dayTimeRange.startHour ?? 0}
          minute={dayTimeRange.startMin ?? 0}
          resolution={resolution}
          onChange={({minute, hour}): void => {
            onChange({...dayTimeRange, startHour: hour, startMin: minute});
          }}
        />
      </Box>
      <Box marginRight={2}>
        <Text>to</Text>
      </Box>
      <TimeDropdown
        hour={dayTimeRange.endHour ?? 23}
        minute={dayTimeRange.endMin ?? 59}
        resolution={resolution}
        onChange={({minute, hour}): void => {
          onChange({...dayTimeRange, endHour: hour, endMin: minute});
        }}
      />
      {Boolean(showDelete) && (
        <Box marginLeft={4}>
          <IconButton
            accessibilityLabel="delete schedule"
            iconName="trash"
            variant="destructive"
            onClick={(): void => onDelete?.()}
          />
        </Box>
      )}
    </Box>
  );
};

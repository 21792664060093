import {FieldConfig, ModelAdminScreen} from "@components";
import {
  useDeleteInsurancePlansByIdMutation,
  useGetInsurancePlansQuery,
  usePatchInsurancePlansByIdMutation,
  usePostInsurancePlansMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

interface Props extends StaffStackScreenProps<"InsurancePlanAdmin"> {}
export const InsurancePlanAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText="Are you sure you want to delete? Make sure you've removed this insurance plan from all users before deleting, or the app will behave weirdly."
      description="Insurance plans are the carriers that provide health insurance to our families through employer or state provided coverage."
      display={(clinician): {title: string; subtitle?: string} => ({title: clinician?.name})}
      fields={
        [
          {fieldKey: "name", type: "text", title: "Name"},
          {
            fieldKey: "billable",
            type: "boolean",
            title: "Billable",
          },
          {
            fieldKey: "acceptingReferrals",
            type: "boolean",
            title: "Accepting Referrals",
          },
        ] as FieldConfig[]
      }
      navigation={navigation}
      useCreate={usePostInsurancePlansMutation}
      useList={useGetInsurancePlansQuery}
      useRemove={useDeleteInsurancePlansByIdMutation}
      useUpdate={usePatchInsurancePlansByIdMutation}
    />
  );
};

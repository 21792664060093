import {useReadProfile} from "@hooks";
import {
  sortUsersByAssigned,
  StaffRoles,
  useGetCarePodsQuery,
  useGetUsersQuery,
  User,
  userName,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {isStaff, pageOnError, UserTypes} from "@utils";
import {
  BooleanField,
  Box,
  Button,
  Heading,
  Icon,
  Page,
  Pagination,
  SelectField,
  Text,
  TextField,
} from "ferns-ui";
import uniqBy from "lodash/uniqBy";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

interface UserPickerRowProps {
  onClick: () => void;
  children: ReactElement;
}

const UserPickerRow = (props: UserPickerRowProps): ReactElement => {
  return (
    <Box
      accessibilityHint="Selects user"
      accessibilityLabel="Select"
      borderBottom="default"
      direction="row"
      flex="grow"
      paddingY={2}
      width="100%"
      onClick={props.onClick}
    >
      <Box flex="grow">{props.children}</Box>
      <Box alignItems="center" justifyContent="center" width={20}>
        <Icon color="secondaryLight" iconName="chevron-right" />
      </Box>
    </Box>
  );
};

interface Props extends StaffStackScreenProps<"UserPicker"> {}

// TODO: add search by name with a regex to User Picker.
export const UserPicker = ({route, navigation}: Props): ReactElement => {
  const types = [];
  if (route.params.staff) {
    types.push(UserTypes.Staff);
  }
  if (route.params.patient) {
    types.push(UserTypes.Patient);
  }
  if (route.params.familyMember) {
    types.push(UserTypes.FamilyMember);
  }
  const currentUser = useReadProfile();
  const [page, setPage] = useState(1);
  const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined);
  const [searchFilter, setSearchFilter] = useState<string>("");

  const [displayTestUsers, setDisplayTestUsers] = useState(false);
  const [displayDisabledUsers, setDisplayDisabledUsers] = useState(false);
  const [carePodId, setCarePodId] = useState<string | undefined>(undefined);
  const {data: carePodData} = useGetCarePodsQuery({});

  const query = {limit: 25, page} as any;
  if (typeFilter) {
    query.type = typeFilter;
  } else {
    query.type = {$in: types};
  }
  if (carePodId) {
    query.carePod = carePodId;
  }
  if (displayTestUsers) {
    query.testUser = true;
  }
  if (displayDisabledUsers) {
    query.disabled = true;
  } else {
    query.disabled = false;
  }
  if (searchFilter) {
    if (searchFilter.match(/^[0-9a-fA-F]{24}$/)) {
      query._id = searchFilter;
    } else if (searchFilter.match(/^\d{6}$/)) {
      query.patientId = searchFilter;
    } else {
      query.name = {$regex: searchFilter.replace(/\\/g, ""), $options: "i"};
    }
  }

  const {data: caseloadUsers} = useGetUsersQuery({
    ...query,
    // Could probably only filter by staff roles that are true.
    $or: [
      {"careTeam.PatientGuide": currentUser?._id},
      {"careTeam.FamilyGuide": currentUser?._id},
      {"careTeam.Therapist": currentUser?._id},
      {"careTeam.Psychiatrist": currentUser?._id},
    ],
    // This should never get over 100, but with family members, could get over 50.
    limit: 100,
    page,
  });

  const {data: otherUsers} = useGetUsersQuery({
    ...query,
    "careTeam.PatientGuide": {$ne: currentUser?._id},
    "careTeam.FamilyGuide": {$ne: currentUser?._id},
    "careTeam.Therapist": {$ne: currentUser?._id},
    "careTeam.Psychiatrist": {$ne: currentUser?._id},
    limit: 100,
    page,
  });

  const totalPages = Math.ceil((otherUsers?.total ?? 0) / 50);

  const {userFilter} = route.params;

  const {
    patientsInCaseload,
    patientsNotInCaseload,
    familyMembersInCaseload,
    familyMembersNotInCaseload,
    staff,
  } = useMemo(() => {
    let allUsers = uniqBy([...(otherUsers?.data ?? []), ...(caseloadUsers?.data ?? [])], "_id");

    if (userFilter) {
      allUsers = allUsers.filter(userFilter);
    }

    return sortUsersByAssigned(allUsers, currentUser);
  }, [currentUser, userFilter, otherUsers?.data, caseloadUsers?.data]);

  // Update the page title
  useEffect(() => {
    if (route.params.title) {
      navigation.setOptions({headerTitle: route.params.title});
    }
  }, [navigation, route.params.title]);

  const renderStaff = function (user: User): ReactElement {
    const roles = Object.keys(user.staffRoles)
      .filter((r) => user.staffRoles[r as StaffRoles] && r !== "_id")
      .join(", ");

    return (
      <Box direction="column" width="100%">
        <Box width="100%">
          <Text size="md">
            {userName(user)} ({user.type})
          </Text>
        </Box>
        <Box width="100%">
          <Text size="sm">{roles}</Text>
        </Box>
      </Box>
    );
  };

  const renderPatientFamilyMember = function (user: User): ReactElement {
    return (
      <Box width="100%">
        <Text size="md">
          {user.name} ({user.type})
        </Text>
      </Box>
    );
  };

  const renderOtherUsers = function (showTestUsers: boolean): ReactElement {
    const patients = patientsNotInCaseload.filter((u) => u.testUser === showTestUsers);
    const familyMembers = familyMembersNotInCaseload.filter((u) => u.testUser === showTestUsers);
    const staffUsers = staff.filter((u) => u.testUser === showTestUsers);
    return (
      <>
        <Box paddingY={3}>
          {Boolean(patients.length) && (
            <Heading size="sm">{showTestUsers ? "Test " : ""}Patients</Heading>
          )}
          {patients.map((u) => (
            <UserPickerRow
              key={u._id}
              onClick={async (): Promise<void> => {
                await route.params.onSelect(u);
                navigation.goBack();
              }}
            >
              {isStaff(u.type) ? renderStaff(u) : renderPatientFamilyMember(u)}
            </UserPickerRow>
          ))}
        </Box>
        <Box paddingY={3}>
          {Boolean(familyMembers.length) && (
            <Heading size="sm">{showTestUsers ? "Test " : ""}Family Members</Heading>
          )}
          {familyMembers.map((u) => (
            <UserPickerRow
              key={u._id}
              onClick={async (): Promise<void> => {
                await route.params.onSelect(u);
                navigation.goBack();
              }}
            >
              {isStaff(u.type) ? renderStaff(u) : renderPatientFamilyMember(u)}
            </UserPickerRow>
          ))}
        </Box>
        <Box paddingY={3}>
          {Boolean(staffUsers.length) && (
            <Heading size="sm">{showTestUsers ? "Test " : ""}Staff </Heading>
          )}
          {staffUsers.map((u) => (
            <UserPickerRow
              key={u._id}
              onClick={async (): Promise<void> => {
                await route.params.onSelect(u);
                navigation.goBack();
              }}
            >
              {isStaff(u.type) ? renderStaff(u) : renderPatientFamilyMember(u)}
            </UserPickerRow>
          ))}
        </Box>
      </>
    );
  };

  return (
    <Page navigation={navigation} scroll onError={pageOnError}>
      <Heading>Choose a user</Heading>
      <Box maxWidth={600}>
        <Box direction="row" marginBottom={4} width="100%">
          <Box flex="grow" marginRight={5}>
            <SelectField
              options={carePodData?.data?.map((cp) => ({label: cp.name, value: cp._id})) ?? []}
              placeholder="All"
              requireValue={false}
              title="Filter By Care Pod"
              value={carePodId}
              onChange={(value: string | undefined): void => {
                setCarePodId(value);
              }}
            />
          </Box>
          <Box flex="grow">
            <SelectField
              options={types.map((t) => ({label: t, value: t}))}
              placeholder="All"
              requireValue={false}
              title="Filter By Type"
              value={typeFilter}
              onChange={setTypeFilter}
            />
          </Box>
        </Box>
        <Box direction="row" gap={4} width="100%">
          <Box flex="grow">
            <TextField
              placeholder="Type a name..."
              title="Search By Name, Patient ID, or Support ID"
              value={searchFilter}
              onChange={setSearchFilter}
            />
          </Box>
          <Box direction="column" gap={2} justifyContent="center" width={160}>
            <Box marginBottom={2} width="100%">
              <BooleanField
                title="Test Users"
                value={displayTestUsers}
                onChange={setDisplayTestUsers}
              />
            </Box>
            <Box width="100%">
              <BooleanField
                title="Disabled Users"
                value={displayDisabledUsers}
                onChange={setDisplayDisabledUsers}
              />
            </Box>
          </Box>
        </Box>

        {Boolean(patientsInCaseload.length) && (
          <Heading size="sm">{`${currentUser?.name}'s`} Patient Panel</Heading>
        )}
        {patientsInCaseload.map((u) => (
          <UserPickerRow
            key={u._id}
            onClick={async (): Promise<void> => {
              await route.params.onSelect(u);
              navigation.goBack();
            }}
          >
            {isStaff(u.type) ? renderStaff(u) : renderPatientFamilyMember(u)}
          </UserPickerRow>
        ))}
        <Box paddingY={4}>
          {Boolean(familyMembersInCaseload.length) && (
            <Heading size="sm">{`${currentUser?.name}'s`} Family Member Panel</Heading>
          )}
          {familyMembersInCaseload.map((u) => (
            <UserPickerRow
              key={u._id}
              onClick={async (): Promise<void> => {
                await route.params.onSelect(u);
                navigation.goBack();
              }}
            >
              {isStaff(u.type) ? renderStaff(u) : renderPatientFamilyMember(u)}
            </UserPickerRow>
          ))}
        </Box>
        {renderOtherUsers(displayTestUsers)}
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        <Box paddingY={2} width={80}>
          <Button
            text="Clear"
            variant="destructive"
            onClick={async (): Promise<void> => {
              await route.params.onSelect(null);
              navigation.goBack();
            }}
          />
        </Box>
      </Box>
    </Page>
  );
};
